import React from "react";
import { Helmet } from "react-helmet";

const Contact = (_) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Contact | Hes Sound</title>
      <description>
        Underground House Music Event Poland Bialystok Warsaw Europe Hes Sound.
        Not just a Sound system, not just a collective, not just a party… 🫀
        Music needs to be moving, but leave some breathing space for the mind
        and soul..
      </description>
    </Helmet>
    <div className="contact">
      <div className="contact_links">
        <a
          href="https://ra.co/promoters/122854"
          target="_blank"
          className="contact_link contact_link--ra"
          rel="noreferrer"
        >
          {/* <span> Resident Advisor</span> */}
        </a>
        <a
          href="https://soundcloud.com/hes_sound"
          target="_blank"
          className="contact_link contact_link--sc"
          rel="noreferrer"
        >
          {/* <span> SoundCloud</span> */}
        </a>
        <a
          href="https://www.instagram.com/hes_sound/"
          target="_blank"
          className="contact_link contact_link--inst"
          rel="noreferrer"
        >
          {/* <span> Instagram</span> */}
        </a>
      </div>
      <form
        className="contact_form"
        name="contact"
        method="POST"
        data-netlify="true"
      >
        <div className="contact_text">
          <p>
            Pytania? Kolaboracja, wydarzenia, <br />
            dyspozycja HES Sound System...
            <br />
            Skontaktuj się z nami!{" "}
          </p>
        </div>
        <input type="hidden" name="form-name" value="contact" />
        <div className="contact_form_input">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className="contact_form_input">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" />
        </div>
        <div className="contact_form_textarea">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="6" />
        </div>
        <ul className="contact_form_buttons">
          <li>
            <button type="submit">
              Send<i class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </form>
    </div>
  </>
);
export default Contact;
